import "./Footer.scss";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import SystemConfig from "../../../SystemConfig";

export default function Footer() {
  const { supportEmail } = SystemConfig;

  return (
    <div className="footer">
      <Grid container item xs={12}>
        <Grid container item md={6} className="footer__textContainer">
          <Typography className="footer__text" color="textSecondary">
            © 2021 Classmate Technolabs Pvt Ltd. All right reserved
          </Typography>
        </Grid>
        <Grid
          container
          item
          md={6}
          justifyContent="flex-end"
          alignItems="center"
          className="footer__textContainer"
        >
          <a href={`mailto:${supportEmail}`}>
            <Typography className="footer__text" color="textSecondary">
              {supportEmail}
            </Typography>
          </a>
        </Grid>
      </Grid>
    </div>
  );
}
