import config from "./config.json";

interface ISystemConfig {
  snackbarTimeout: number;
  supportEmail: string;
  appUrl: string;
  appPackageId: string;
}

const SystemConfig: ISystemConfig = {
  snackbarTimeout: 3000,
  supportEmail: "contact@classmate-technolabs.com",
  appUrl: "https://www.classmate-technolabs.com",
  appPackageId: "guru.classmate",
};

export default SystemConfig;
