import "./Header.scss";
import Grid from "@mui/material/Grid";
import LogoHorizontal from "../../../assets/logo-horizontal.svg";
import MainMenu from "./MainMenu";
import { Link } from "react-router-dom";

export default function Header() {
  return (
    <div className="header">
      <Grid container item xs={12}>
        <Grid container item xs={6}>
          <Link to={"/"}>
            <img className="header__logo" alt="logo" src={LogoHorizontal} />
          </Link>
        </Grid>
        <Grid
          container
          item
          xs={6}
          justifyContent="flex-end"
          alignItems="center"
        >
          <MainMenu />
        </Grid>
      </Grid>
    </div>
  );
}
