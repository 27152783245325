import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import MenuIcon from "@mui/icons-material/Menu";
import Public from "@mui/icons-material/Public";
import Gavel from "@mui/icons-material/Gavel";
import Policy from "@mui/icons-material/Policy";
import Money from "@mui/icons-material/Money";
import Phone from "@mui/icons-material/Phone";
import Subject from "@mui/icons-material/Subject";
import SystemConfig from "../../../SystemConfig";
import history from "history/browser";
import { Link } from "react-router-dom";

export default function MainMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const goWeb = () => {
    window.location.href = SystemConfig.appUrl;
  };

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="View all">
          <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
            <Avatar sx={{ width: 32, height: 32 }}>
              <MenuIcon fontSize="small" />
            </Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={goWeb}>
          <ListItemIcon>
            <Public fontSize="small" />
          </ListItemIcon>
          Go to web app
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => window.open("/license.html")}>
          <ListItemIcon>
            <Gavel fontSize="small" />
          </ListItemIcon>
          Terms and condition
        </MenuItem>
        <MenuItem onClick={() => window.open("/privacy")}>
          <ListItemIcon>
            <Policy fontSize="small" />
          </ListItemIcon>
          Privacy policy
        </MenuItem>
        <Link to={"/refund"}>
          <MenuItem onClick={() => history.push("/refund")}>
            <ListItemIcon>
              <Money fontSize="small" />
            </ListItemIcon>
            Refund and Cancellations
          </MenuItem>
        </Link>
        <Link to={"/aboutus"}>
          <MenuItem>
            <ListItemIcon>
              <Subject fontSize="small" />
            </ListItemIcon>
            About us
          </MenuItem>
        </Link>
        <Link to={"/contact"}>
          <MenuItem>
            <ListItemIcon>
              <Phone fontSize="small" />
            </ListItemIcon>
            Contact us
          </MenuItem>
        </Link>
      </Menu>
    </React.Fragment>
  );
}
