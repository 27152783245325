import {
  getAuth,
  signInAnonymously as signInAnonymouslyFirebase,
  onAuthStateChanged as onAuthStateChangedFirebase,
  User,
} from "firebase/auth";

const signInAnonymously = async () => {
  try {
    return await signInAnonymouslyFirebase(getAuth());
  } catch (error) {
    console.error("TCL: signInAnonymously -> error", error);
  }
};

export const onAuthStateChanged = async (): Promise<User> => {
  return new Promise((resolve: any, reject: any) => {
    onAuthStateChangedFirebase(getAuth(), async (user) => {
      console.log("TCL: onAuthStateChanged -> user");
      const authUser = user ?? (await signInAnonymously())?.user;
      //setAccessToken(authUser);
      authUser ? resolve(authUser) : reject();
    });
  });
};

export const getCurrentUser = () => getAuth().currentUser;
