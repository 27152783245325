import "./App.scss";
import React, { Suspense } from "react";
import { FirebaseProvider } from "./common/components/FirebaseProvider";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CustomThemeOptions } from "./config/palette";
import SystemConfig from "./SystemConfig";
import { SnackbarProvider } from "notistack";
import { MainLayout } from "./layouts/mainLayout/MainLayout";
import Header from "./features/header/components/Header";
import Footer from "./features/footer/components/Footer";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Loader from "./common/components/loader/Loader";

const Contents = React.lazy(
  () => import("./features/contents/components/Contents")
);

const RefundPolicy = React.lazy(
  () => import("./features/contents/components/RefundPolicy")
);

const ContactUs = React.lazy(
  () => import("./features/contents/components/ContactUs")
);

const AboutUs = React.lazy(
  () => import("./features/contents/components/AboutUs")
);

const Privacy = React.lazy(
  () => import("./features/contents/components/Privacy")
);

function App() {
  return (
    <BrowserRouter>
      <FirebaseProvider>
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            maxSnack={3}
            autoHideDuration={SystemConfig.snackbarTimeout}
            preventDuplicate={true}
          >
            <MainLayout>
              <Header />
              <Suspense
                fallback={
                  <div className="splash-screen">
                    <Loader type="brand" />
                  </div>
                }
              >
                <Routes>
                  <Route path="/" element={<Contents />} />
                  <Route path="/refund" element={<RefundPolicy />} />
                  <Route path="/contact" element={<ContactUs />} />
                  <Route path="/aboutus" element={<AboutUs />} />
                  <Route path="/privacy" element={<Privacy />} />
                </Routes>
              </Suspense>
              <Footer />
            </MainLayout>
          </SnackbarProvider>
        </ThemeProvider>
      </FirebaseProvider>
    </BrowserRouter>
  );
}

const theme = createTheme(CustomThemeOptions);

export default App;
