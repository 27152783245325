import { ThemeOptions } from "@mui/material";
import {
  DefaultFont,
  DefaultFontBold,
  DefaultPrimaryColor,
} from "./themeConfig";
import colors from "../styles/config.module.scss";

export const CustomThemeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: colors.primaryColor,
    },
    secondary: {
      main: colors.secondaryColor,
    },
    mode: "dark",
  },
  typography: {
    h1: {
      fontFamily: DefaultFont,
    },
    h2: {
      fontFamily: DefaultFont,
    },
    h3: {
      fontFamily: DefaultFont,
    },
    h4: {
      fontFamily: DefaultFont,
    },
    h5: {
      fontFamily: DefaultFont,
      color: colors.secondaryColor,
    },
    h6: {
      fontFamily: DefaultFont,
      color: colors.secondaryColor,
    },
    body1: {
      fontFamily: DefaultFont,
      color: colors.secondaryColor,
    },
  },
};
